import React, { Component } from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import PageHeader from "../components/page-header"
import MainButton from "../components/main-button"
import { Colors } from "../colors/Colors"
import { LoginUser } from "../services/parse-bridge"
import Loader from "react-loader-spinner"
import { navigate } from "gatsby"

class LoginPage extends Component {
  state = {
    usernmae: "",
    password: "",
    loading: false,
  }

  updateInput = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    this.setState({ loading: true })
    e.preventDefault()
    const { username, password } = this.state

    LoginUser(username.trim().toLowerCase(), password).then(res => {
      this.setState({ loading: false })
      if (res) {
        navigate("/reviews")
      } else {
        console.log("Something went wrong loggin in")
      }
    })
  }

  render() {
    const { username, password, loading } = this.state
    return (
      <Layout>
        <PageHeader image={require("../images/candid.jpg")} />
        <Container>
          <Title>Log in to continue</Title>
          <Form onSubmit={this.handleSubmit}>
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              type="text"
              name="username"
              value={username}
              onChange={this.updateInput}
            />
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              name="password"
              value={password}
              onChange={this.updateInput}
            />
            <MainButton type="submit">Login</MainButton>
            {loading && (
              <Spinner>
                <Loader
                  type="Oval"
                  color={Colors.gold}
                  height={50}
                  width={50}
                />
              </Spinner>
            )}
          </Form>
        </Container>
      </Layout>
    )
  }
}
export default LoginPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;
  padding: 20px;
  margin: 80px auto;
`
const Title = styled.h1`
  margin-bottom: 80px;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Label = styled.label`
  font-family: "Articulat";
  color: ${Colors.gold};
  font-weight: 600;
  margin-bottom: 5px;
`
const Input = styled.input`
  margin-bottom: 30px;
  padding: 7px 10px;
  font-family: "Articulat";
  font-size: 17px;
  width: 300px;
  border: 1px solid #c9c9c9;
  border-radius: 2px;
`
const Spinner = styled.div`
  text-align: center;
  margin: 20px 0;
  /* min-height: 550px; */
`
